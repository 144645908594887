import { IconButton } from '@mindkit';
import { IconCross, IconSearch } from '@mindkit/icons';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'shared/helpers/debounce';

const SearchBar: React.FC<{ className?: string }> = ({ className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');

  const [autoFocus] = useState(searchParams.get('focus') === 'true');
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const actualSearch = searchParams.get('search') || '';
    if (!actualSearch) {
      setSearch('');
    }
  }, [searchParams]);

  const applySearch = useMemo(
    () =>
      debounce((search: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams);

        if (search) {
          updatedSearchParams.set('search', search);
        } else {
          updatedSearchParams.delete('search');
        }

        setSearchParams(updatedSearchParams);
      }, 300),
    [searchParams, setSearchParams],
  );

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const search = formData.get('search') as string;

    applySearch(search);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    setSearch(search);

    if (search.length === 0 || search.length > 1) {
      applySearch(e.target.value);
    }
  };

  return (
    <form
      className={clsx(
        'flex h-16 flex-row items-center rounded-full bg-level1 pl-6 pr-6 desk:shadow-lg',
        className,
      )}
      onSubmit={handleSearch}
    >
      <IconSearch className="mr-4 shrink-0 text-disabled" />
      <input
        ref={inputRef}
        className="mr-4 w-full appearance-none bg-inherit outline-none"
        placeholder="Search for coaches"
        name="search"
        value={search}
        onChange={handleChange}
      />

      <IconButton
        className={clsx({ hidden: search.length === 0 })}
        onClick={() => {
          setSearch('');
          applySearch('');
        }}
      >
        <IconCross />
      </IconButton>
    </form>
  );
};

export default SearchBar;
