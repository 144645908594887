import { Button } from '@mindkit';
import { IllustrationSandClock } from '@mindkit/illustrations';
import clsx from 'clsx';
import BookingItem from 'modules/booking/components/BookingItem/BookingItem';
import { Booking } from 'modules/booking/model';
import React from 'react';
import { Link } from 'react-router-dom';
import { TelegramService } from 'services/telegram';
import TimezoneLabel from 'shared/components/TimezoneLabel/TimezoneLabel';

const UserUpcomingCalls: React.FC<{ upcoming: Booking[]; className?: string }> = ({
  upcoming,
  className,
}) => {
  const isEmpty = !upcoming.length;

  let redirect = '/';
  if (TelegramService.isTelegramApp()) {
    redirect = TelegramService.getSafeRootRedirect();
  }

  let body;
  if (isEmpty) {
    body = (
      <div className="flex flex-col items-center desk:pb-8">
        <IllustrationSandClock className="self-center" />
        <p className="mt-5 self-center text-center">
          Hey! You do not have any booked sessions for now.
        </p>
        <Button className="mt-8 w-full self-center desk:w-auto" as={Link} to={redirect}>
          Find a coach
        </Button>
      </div>
    );
  } else {
    body = (
      <div className="flex flex-col gap-2 desk:gap-4">
        {upcoming.map((booking) => {
          return <BookingItem key={booking.id} booking={booking} />;
        })}
      </div>
    );
  }

  return (
    <section className={clsx('page-section', className)}>
      <div className="page-section-header">
        <h2>
          Upcoming calls{' '}
          <span className="font-normal text-secondary mobile:block mobile:text-base">
            (<TimezoneLabel isFull />)
          </span>
        </h2>
      </div>

      {body}
    </section>
  );
};

export default UserUpcomingCalls;
