import React from 'react';
import { Helmet } from 'react-helmet';

const GlobalHelmet: React.FC = () => {
  return (
    <Helmet>
      <title>Find an expert with Avatai</title>
      <meta
        name="description"
        content="Find an expert to help you with your personal and professional growth with Avatai."
      />

      <meta property="og:title" content="Find an expert with Avatai" />
      <meta
        property="og:description"
        content="Find an expert to help you with your personal and professional growth with Avatai."
      />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content="/logo.svg" />
    </Helmet>
  );
};

export default GlobalHelmet;
