import { Button, Loader } from '@mindkit';
import { IconArrowRightSm } from '@mindkit/icons';
import { IllustrationEmptySearch } from '@mindkit/illustrations';
import BetaPromoButton from 'modules/betaPromo/components/BetaPromoButton/BetaPromoButton';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import MentorCatalogItem from 'modules/mentor/components/MentorCatalogItem/MentorCatalogItem';
import SearchBar from 'modules/mentor/components/SearchBar/SearchBar';
import useMentorCatalog from 'modules/mentor/hooks/useMentorCatalog';
import { mentorHelpers } from 'modules/mentor/model';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const MentorCatalogPage: React.FC = () => {
  const { data: catalog, isError, isPending } = useMentorCatalog();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search') || '';

  if (isError) {
    return <p className="m-auto text-danger">Something went wrong ...</p>;
  }
  if (isPending) {
    return <Loader className="m-auto" />;
  }

  const handleResetSearch = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete('search');
    setSearchParams(updatedSearchParams);
  };

  const filteredCatalog = mentorHelpers.filterCatalog(catalog, search);

  return (
    <PageLayout
      bodyClassName="items-center bg-level2"
      body={
        <>
          <SearchBar className="mb-4 w-full max-w-2xl desk:mb-12 desk:mt-4" />

          {filteredCatalog.length > 0 ? (
            <div className="grid w-full grid-cols-1 gap-4 desk:grid-cols-3 desk:gap-8">
              {filteredCatalog.map((mentor) => (
                <MentorCatalogItem key={mentor.id} mentor={mentor} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center text-center">
              <IllustrationEmptySearch className="mb-4 desk:mb-8" />

              <p>We&apos;re sorry, but we couldn&apos;t find anything matching your search</p>
              <p>Feel free to try again or explore our list of our Experts!</p>

              <Button
                className="mt-4 desk:mt-8"
                variant="outlined"
                color="primary dark:primary-inverse"
                onClick={handleResetSearch}
              >
                See all experts
                <IconArrowRightSm className="ml-2" />
              </Button>
            </div>
          )}
        </>
      }
      footer={
        <>
          {/* <BetaPromoButton /> */}

          {/* <div className="mt-6">
            <a
              className="underline hover:text-primary"
              href="https://app.avatai.co/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <span className="mx-1">&</span>
            <a
              className="underline hover:text-primary"
              href="https://app.avatai.co/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </div> */}

          <p className="text-sm text-secondary">© 2024 Avatai. All rights reserved.</p>
        </>
      }
      footerClassName="gap-4"
    />
  );
};

export default MentorCatalogPage;
