import { Mentor } from 'modules/mentor/model';
import React from 'react';
import { Helmet } from 'react-helmet';

const MentorPageHelmet: React.FC<{ mentor: Mentor }> = ({ mentor }) => {
  return (
    <Helmet>
      <title>{mentor.profileDetails.name} - Avatai</title>
      <meta name="description" content={mentor.profileDetails.description} />

      <meta property="og:title" content={mentor.profileDetails.name} />
      <meta property="og:description" content={mentor.profileDetails.description} />
      {mentor.profileDetails.photoUrl && (
        <meta property="og:image" content={mentor.profileDetails.photoUrl} />
      )}
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  );
};

export default MentorPageHelmet;
