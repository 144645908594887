import { IconSearch } from '@mindkit/icons';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const GeneralSearchBar: React.FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const search = formData.get('search') as string;
    navigate(`/?search=${search}`);
  };

  return (
    <form
      className={clsx(
        'flex h-12 flex-row items-center rounded-full border border-default pl-2 pr-4 dark:border-disabled',
        className,
      )}
      onSubmit={handleSearch}
    >
      <IconSearch className="mr-2 shrink-0 text-disabled" />
      <input
        className="h-full appearance-none border-none bg-inherit outline-none"
        name="search"
        placeholder="Search for coaches"
      />
    </form>
  );
};

export default GeneralSearchBar;
